@import url("./vendor/leaflet.css");

.fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  height: 100vh;
  width: 100vw;
}

/* to make table action header aligned to the right side  */
.MuiTable-root {
  .table-actions-header {
    .Mui-TableHeadCell-Content {
      justify-content: flex-end;
    }
  }
}
